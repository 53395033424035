import React from 'react';

function ServicesHomeOne({ className }) {
  return (
    <section
      className={`appie-service-area pt-90 pb-100 ${className}`}
      id="service"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h4 className="appie-title">Full-Service Software Development</h4>
              <br></br>
              <h5>Everything you need in one complete solution</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <i
                  className="fas fa-database fa-2x"
                  style={{ color: 'white' }}
                ></i>
                <span>1</span>
              </div>
              <h4 className="appie-title">Database Design</h4>
              <p>
                Design robust databases with ease. Our no-code platform empowers
                you to structure and manage data efficiently, without needing to
                write complex code.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <i
                  className="fas fa-palette fa-2x"
                  style={{ color: 'white' }}
                ></i>
                <span>2</span>
              </div>
              <h4 className="appie-title">UI/UX Design</h4>
              <p>
                Create stunning, user-friendly interfaces. With our intuitive
                drag-and-drop tools, designing beautiful and functional UI/UX is
                simpler than ever.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <i className="fas fa-cogs fa-2x" style={{ color: 'white' }}></i>
                <span>3</span>
              </div>
              <h4 className="appie-title">Business Logic & API</h4>
              <p>
                Implement business logic seamlessly. Connect blocks to integrate
                APIs and automate processes, implementing functionality without
                coding.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <i
                  className="fas fa-cloud fa-2x"
                  style={{ color: 'white' }}
                ></i>

                <span>4</span>
              </div>
              <h4 className="appie-title">Cloud Deployment</h4>
              <p>
                Deploy on the cloud effortlessly. Our platform simplifies cloud
                deployment, enabling you to launch and scale applications
                quickly and securely.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesHomeOne;
