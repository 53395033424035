import React from 'react';

function Forms() {
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact--info-area">
                <h3>Get in touch</h3>
                <p>
                  Looking for help? Fill the form and start a new adventure.
                </p>
                <div className="single-info">
                  <h5>Headquaters</h5>
                  <p>
                    <i className="fal fa-home"></i>
                    744 New York Ave, Brooklyn, Kings,
                    <br /> New York 10224
                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <i className="fal fa-phone"></i>
                    (+642) 245 356 432
                    <br />
                    (+420) 336 476 328
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <i className="fal fa-envelope"></i>
                    bisy@support.com
                    <br />
                    help@education.com
                  </p>
                </div>
                <div className="ab-social">
                  <h5>Follow Us</h5>
                  <a className="fac" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="twi" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="you" href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a className="lin" href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="contact-form">
                <h4>Let’s Connect</h4>
                <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p>
                <form action="#" method="post" className="row">
                  <div className="col-md-6">
                    <label htmlFor="f-name">First Name:</label>
                    <input
                      id="f-name"
                      type="text"
                      name="f-name"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="l-name">Last Name:</label>
                    <input
                      id="l-name"
                      type="text"
                      name="l-name"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email">Email Address:</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phone">Phone Number:</label>
                    <input
                      id="phone"
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="subject">Subject:</label>
                    <input
                      id="subject"
                      type="text"
                      name="subject"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="message">Message:</label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="How can we help?"
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <div className="condition-check">
                      <input
                        id="terms-conditions"
                        type="checkbox"
                        name="terms-conditions"
                      />
                      <label htmlFor="terms-conditions">
                        I agree to the <a href="#">Terms & Conditions</a>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 text-right">
                    <input type="submit" name="submit" value="Send Message" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bisylms-map">
        <iframe
          title="map"
          src="https://maps.google.com/maps?width=720&height=600&hl=en&coord=39.966528,-75.158284&q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&ie=UTF8&t=&z=16&iwloc=B&output=embed"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default Forms;
