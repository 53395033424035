import React from 'react';
import blogImg1 from '../../assets/images/blog/1.jpg';
import blogImg2 from '../../assets/images/blog/2.jpg';
import blogImg3 from '../../assets/images/blog/3.jpg';
import blogImg4 from '../../assets/images/blog/4.jpg';
import blogImg5 from '../../assets/images/blog/5.jpg';
import blogImg6 from '../../assets/images/blog/6.jpg';
import blogImg7 from '../../assets/images/blog/7.jpg';
import blogImg8 from '../../assets/images/blog/8.jpg';

function Blogs() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg1} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="/news/single-news">
                  Build A Full Web Chat App From Scratch.
                </a>
              </h3>
              <a className="read-more" href="single-post.html">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg2} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="/news/single-news">
                  Insights on How to Improve Your Teaching.
                </a>
              </h3>
              <a className="read-more" href="/news/single-news">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg3} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="/news/single-news">
                  Brush Strokes Energize Trees In Paintings
                </a>
              </h3>
              <a className="read-more" href="/news/single-news">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg4} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="/news/single-news">
                  Learning Python for Data Analysis.
                </a>
              </h3>
              <a className="read-more" href="/news/single-news">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg5} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="single-post.html">
                  Logotype Masterclass with Jessica Hische
                </a>
              </h3>
              <a className="read-more" href="single-post.html">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg6} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="/news/single-news">
                  Build A Full Web Chat App From Scratch.
                </a>
              </h3>
              <a className="read-more" href="single-post.html">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg7} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="single-post.html">
                  Learning Python for Data Analysis.
                </a>
              </h3>
              <a className="read-more" href="single-post.html">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="post-item-1">
            <img src={blogImg8} alt="" />
            <div className="b-post-details">
              <div className="bp-meta">
                <a href="#">
                  <i className="fal fa-clock"></i>April 22, 2020
                </a>
                <a href="#">
                  <i className="fal fa-comments"></i>6 Comments
                </a>
              </div>
              <h3>
                <a href="single-post.html">
                  Learning Python for Data Analysis.
                </a>
              </h3>
              <a className="read-more" href="single-post.html">
                Read More<i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="bisylms-pagination">
            <span className="current">01</span>
            <a href="#">02</a>
            <a className="next" href="#">
              next<i className="fal fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blogs;
