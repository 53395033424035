import React, { useEffect, useState } from 'react';
import imgColorExplosion from '../../assets/images/color-explosion.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

const phrases = [
  'a Website',
  'a Social Media Platform',
  'an E-Shop',
  'a Customer Relationship Management (CRM)',
  'a Project Management Application',
  'Marketing Automation',
  'a Content Management System (CMS)',
  'a Blog',
  'an Artificial Intelligence (AI) Assistant',
  'a Blockchain-based Solution',
  'a Telehealth Service',
  'a Sustainable Tech Initiative',
  'a Smart City Interface',
  'a Personalized Learning Environment',
  'Anything You Want',
  'Your Dream Idea',
];

function HeroHomeOne({ className }) {
  const [dynamicText, setDynamicText] = useState('');

  useEffect(() => {
    let phraseIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let timerId;

    const type = () => {
      const currentPhrase = phrases[phraseIndex];
      const isEnd = charIndex === currentPhrase.length;
      const isStart = charIndex === 0;
      const pauseBeforeDeleting = 1000;

      if (isDeleting) {
        if (charIndex > 0) {
          setDynamicText(currentPhrase.substring(0, charIndex));
          charIndex -= 1;
        }
      } else {
        setDynamicText(currentPhrase.substring(0, charIndex));
        charIndex += 1;
      }

      if (isEnd) {
        if (!isDeleting) {
          timerId = setTimeout(() => {
            isDeleting = true;
            type();
          }, pauseBeforeDeleting);
          return;
        }
      }

      if (isStart && isDeleting) {
        isDeleting = false;
        phraseIndex = (phraseIndex + 1) % phrases.length;
        charIndex = 0;
      }

      const typingDelay = 80;
      const deletingDelay = typingDelay / 3;
      const delay = isEnd || isDeleting ? deletingDelay : typingDelay;

      timerId = setTimeout(type, delay);
    };

    type();

    return () => clearTimeout(timerId); // Clear timeout on component unmount
  }, []);

  return (
    <>
      <section className={`appie-hero-area ${className || ''}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content contact-form">
                <h1 className="appie-title">Unleash your potential.</h1>
                <h2 className="dynamic-text-container">Create {dynamicText}</h2>
                <h5>What's your idea:</h5>
                <input
                  id="idea"
                  type="text"
                  name="idea"
                  placeholder="E-commerce store selling handmade crafts"
                />
                <ul>
                  <li>
                    <a href="#">
                      <i className="fas fa-plus"></i> Create
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={imgColorExplosion} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shape-1">
          <img src={shapeTwo} alt="" />
        </div>
        <div className="hero-shape-2">
          <img src={shapeThree} alt="" />
        </div>
        <div className="hero-shape-3">
          <img src={shapeFour} alt="" />
        </div>
      </section>
    </>
  );
}

export default HeroHomeOne;
