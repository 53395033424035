import React from 'react';
import aboutThumb from '../../assets/images/about-thumb-2.png';
import aboutThumb3 from '../../assets/images/about-thumb-3.png';
import icon1 from '../../assets/images/icon/1.png';
import icon5 from '../../assets/images/icon/5.svg';
import icon6 from '../../assets/images/icon/6.svg';
import icon7 from '../../assets/images/icon/7.svg';

function AboutHomeThree() {
  return (
    <>
      <section className="appie-about-3-area pt-100 pb-100" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="appie-about-thumb-3 wow animated fadeInLeft"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <img src={aboutThumb} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-traffic-title">
                <h3 className="title">
                  Appie lets you launch your app in minutes.
                </h3>
                <p>
                  He nicked it tickety boo harry the cras bargy chap mush
                  spiffing spend a penny the full monty burke butty.
                </p>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <img src={icon5} alt="" />
                    </div>
                    <h5 className="title">Carefully designed</h5>
                    <p>Mucker plastered bugger all mate morish are.</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service item-2 mb-30">
                    <div className="icon">
                      <img src={icon1} alt="" />
                    </div>
                    <h5 className="title">Seamless Sync</h5>
                    <p>Mucker plastered bugger all mate morish are.</p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="traffic-btn mt-50">
                    <a className="main-btn" href="#">
                      Learn More <i className="fal fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-100 flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              <div className="appie-traffic-title">
                <h3 className="title">Browse over 40k Apps over the world</h3>
                <p>
                  He nicked it tickety boo harry the cras bargy chap mush
                  spiffing spend a penny the full monty burke butty.
                </p>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30 item-3">
                    <div className="icon">
                      <img src={icon6} alt="" />
                    </div>
                    <h5 className="title">User Interactive</h5>
                    <p>Mucker plastered bugger all mate morish are.</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service item-2 mb-30 item-4">
                    <div className="icon">
                      <img src={icon7} alt="" />
                    </div>
                    <h5 className="title">Choose a App</h5>
                    <p>Mucker plastered bugger all mate morish are.</p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="traffic-btn mt-50">
                    <a className="main-btn" href="#">
                      Learn More <i className="fal fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <img src={aboutThumb3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutHomeThree;
