import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';

function Start() {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const code = currentUrl.searchParams.get('code');
    if (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        client_id: '7se4k9jis08hc0mn6a9p2knorr',
        code: code,
        redirect_uri: 'https://www.creatorx.cc/start/',
      });

      const config = {
        method: 'post',
        url: 'https://creatorx.auth.us-west-2.amazoncognito.com/oauth2/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log('Token exchange successful:', response.data);
          const { idToken } = response.data;
          const decoded = JSON.parse(window.atob(idToken.split('.')[1]));
          setUserInfo({
            email: decoded.email,
            username: decoded['cognito:username'],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div>
      <h1>Start Page</h1>
      {userInfo.email && (
        <div>
          <p>Email: {userInfo.email}</p>
          <p>Username: {userInfo.username}</p>
        </div>
      )}
    </div>
  );
}

export default Start;
