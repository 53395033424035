import React, { useState } from 'react';

function FaqHomeRtl({ className }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };
  return (
    <>
      <section className={`appie-faq-area pb-95 ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">أسئلة مكررة</h3>
                <p>تخطيطات وأنماط مختلفة لأقسام الفريق.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner text-right">
                      <div className="accrodion-title">
                        <h4>أين أجد عادة الأسئلة الشائعة في الصفحة؟</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح
                            اليرقة السخيفة التي أتعامل معها.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(2)}
                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner text-right">
                      <div className="accrodion-title">
                        <h4>أين أجد عادة الأسئلة الشائعة في الصفحة؟</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 2 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح
                            اليرقة السخيفة التي أتعامل معها.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner text-right">
                      <div className="accrodion-title">
                        <h4>أين أجد عادة الأسئلة الشائعة في الصفحة؟</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح
                            اليرقة السخيفة التي أتعامل معها.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(4)}
                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner text-right">
                      <div className="accrodion-title">
                        <h4>أين أجد عادة الأسئلة الشائعة في الصفحة؟</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 4 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح
                            اليرقة السخيفة التي أتعامل معها.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  لا يمكنك العثور على إجابة؟
                  <a href="mailto:demo@gmail.com">
                    ارسل لنا عبر البريد الإلكتروني
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeRtl;
