import React from 'react';

function HeroAbout() {
  return (
    <>
      <div className="appie-about-top-title-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h2 className="title">
                  The leading people Management platform
                </h2>
                <p>We offer you a panoply of cutting-edge options.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appie-about-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appie-about-page-content">
                <h3 className="title">
                  Join our team to create the best digital solutions.
                </h3>
                <p>
                  He nicked it up the duff a load of old tosh bleeder butty
                  bugger all mate cheeky bugger bodge tickety boo, mush well
                  Richard geeza buggered haggle david you mug I, such a fibber
                  my good sir knackered down the pub baking cakes chancer golly
                  gosh. Car boot give us a bell bits and hanky panky me old
                  mucker fantastic in my flat so I said nice one he lost his
                  bottle.
                </p>
                <a href="#">
                  View all Members <i className="fal fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroAbout;
